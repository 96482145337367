import InfoIcon from '@material-ui/icons/Info';

const ContactInfo = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="contact-info">
      <div className='contact-informations'>
        <p>Τηλέφωνο: <span><a href="tel: 2106107623"> 2106107623</a></span></p>
        <p>E-mail: <span><a href="mailto: servicedynamotors@gmail.com">servicedynamotors@gmail.com</a></span></p>
        <p>Φαξ: <span>2106100582</span></p>
      </div>
      <div className="contact-workshops">
        <p>Συνεργεία:</p>
        <ul>
          <li>
            Μαρούσι
            <InfoIcon
              onClick={scrollToTop}
              className='information-icon'
            />
          </li>
          <li>
            Λυκόβρυση
            <InfoIcon
              onClick={scrollToTop}
              className='information-icon'
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
 
export default ContactInfo;