export default function validateInfo(values) {
  let errors = {};
  const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

  if (!values.fullName) {
    errors.fullName = 'Απαιτείται';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Απαιτείται';
  }
  else if (values.phoneNumber.length < 10 ) {
    errors.phoneNumber = 'Πληκτρολογήστε ένα σωστό νούμερο';
  }

//  if (!values.email) {
//    errors.email = 'Απαιτείται';
//  }
//  else 
  if (!EMAIL_REGEXP.test(values.email) && values.email != "") {
    errors.email = 'Πληκτρολογήστε ένα σωστό email';
  }

  if (!values.message) {
    errors.message = 'Απαιτείται';
  } else if (values.message.length <= 3) {
    errors.message = 'Παρακαλούμε συμπληρώστε πάνω απο 3 χαρακτήρες';
  }
  return errors;
}