import React, { useState, useEffect } from 'react'
import Carousel from '../../components/screenComponents/carousel/Carousel'
import Accordion from './Accordion';
import { accordionData } from '../../utils/accordionData'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './servicesScreen.css'
import { CarouselData } from '../../components/screenComponents/carousel/CarouselData';

const Services = () => {

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <>
      <Carousel
        slides={CarouselData}
      />
      <div className="services-screen">
        <div className='services-screen-vision'>
          <h2>Το όραμά μας</h2>
          <p>Φροντίδα αυτοκινήτου με συνδυασμό <strong>ποιότητας</strong> και <strong>χαμηλού κόστους</strong> για <strong>όλες</strong> τις ευρωπαϊκές μάρκες.</p>
          <p>Η πολυετής εμπειρία μας στον χώρο των αυτοκινήτων μας βοήθησε να βρούμε την κατάλληλη λύση για οτιδήποτε σας προβληματίζει.</p>
        </div>
        <div className="services-screen-solutions">
          <h3>Λύσεις Dynamotors</h3>
          {accordionData.map(({ id, solution, title, content }) => (
            <Accordion
              key={id}
              id={id}
              solution={solution}
              title={title} 
              content={content}
            />
          ))}
          <div className='accordion'>
            { isActive ?
              <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <div>{ isActive ? <ExpandMoreIcon /> :  <ChevronRightIcon /> }</div>
                <div>Ταλαιπωρείστε όταν τρακάρετε;</div>
              </div> :
              <div className="isnotactive-accordion-title" onClick={() => setIsActive(!isActive)}>
                <div>{ isActive ? <ExpandMoreIcon /> :  <ChevronRightIcon /> }</div>
                <div>Ταλαιπωρείστε όταν τρακάρετε;</div>
              </div> 
            }
            {isActive &&
            <div className="accordion-content">
              <h4>Στο φανοποιείο μας προσφέρουμε επιπλέον διευκολύνσεις που θα κάνουν τη ζωή σας ευκολότερη!</h4>
              <ul className="accordion-list">
                <li>Είσπραξη απευθείας από ασφαλιστικές εταιρείες</li>
                <li>Δωρεάν αυτοκίνητο αντικατάστασης</li>
                <li>Δωρεάν έλεγχος 22 σημείων</li>
                <li>Δωρεάν πλύσιμο οχήματος σε κάθε εργασία φανοποιείου και βαφής</li>
                <li>Χρώματα υδατοδιαλυτά SIKKENS με 5ετή εγγύηση</li>
                <li>Ακριβής χρόνος αποπεράτωσης της επισκευής του αυτοκινήτου σας</li>
                <li>Έχουμε σύμβαση συνεργασίας με τις περισσότερες ασφαλιστικές εταιρίες που μας εμπιστεύονται για την υψηλή ποιότητα 
                  επισκευής και την ακρίβεια στο χρόνο παράδοσης του οχήματός σας. (Interamerican, Generali, Ευρωπαϊκή Πίστη, Chartis, Εθνική ασφαλιστική, 
                  Ατλαντική ένωση, AIG, Ergo, Allianz, Μινέτα, Interlife, Hellas Direct, Groupama). Αλλά και για τις υπόλοιπες ασφαλιστικές εταιρείες 
                  ισχύουν τα ανωτέρω
                </li>
              </ul>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
}
 
export default Services;