import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import logo from '../../images/logo.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Link } from 'react-router-dom';
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <div className='workshops-footer-schedule'>
          <div className='workshops-footer-section'> 
            <h3>
              <Link to="/diktyo-synergeion">Συνεργεία</Link>
            </h3>
            <p className='workshop-footer'>
              <a href="https://www.google.com/maps?q=%CE%9C%CE%B5%CF%83%CE%BF%CE%B3%CE%B5%CE%AF%CF%89%CE%BD+131,+%CE%9C%CE%B1%CF%81%CE%BF%CF%8D%CF%83%CE%B9+151+26" target='_blank'>
                <LocationOnIcon 
                  className="icon"
                />
                <strong>Μαρούσι, οδός Μεσογείων 131</strong>
              </a>
              <a href="tel: 2106107623">
                <PhoneIcon 
                  className="icon"
                />
                2106107623
              </a>
              <a href = "mailto: servicedynamotors@gmail.com">
                <EmailIcon
                  className="icon"
                />
                servicedynamotors@gmail.com
              </a>
            </p>
            <p className='workshop-footer'>
              <a href="https://www.google.com/maps?q=%CE%9B%CE%B5%CF%89%CF%86.+%CE%A3%CE%BF%CF%86%CE%BF%CE%BA%CE%BB%CE%AE+%CE%92%CE%B5%CE%BD%CE%B9%CE%B6%CE%AD%CE%BB%CE%BF%CF%85+112,+%CE%9B%CF%85%CE%BA%CF%8C%CE%B2%CF%81%CF%85%CF%83%CE%B7+141+23" target='_blank'>
                <LocationOnIcon 
                  className="icon"
                />
                <strong>Λυκόβρυση, Λεωφ. Σοφοκλή Βενιζέλου 112</strong>
              </a>
              <a href="">
                <PhoneIcon 
                  className="icon"
                />
                2102853682
              </a>
              <a href="">
                <EmailIcon 
                  className="icon"
                />
                bmw.dynamotors@gmail.com
              </a>
            </p>
          </div>
          <div className="schedule-hour">
            <h3>Ωράριο Λειτουργίας</h3>
            <p>Δευτέρα - Παρασκευή
              <span> 8:00 - 17:00</span>
            </p>
            <p>Σάββατο
              <span> 9:00 - 13:00</span>
            </p>
            <p className='schedule-marousi'>(<strong>Μόνο</strong> συνεργείο Αμαρουσίου)</p>
          </div>
        </div>
        <div className='footer-services'>
          <h3 className='services'>
            <Link to="/services">Υπηρεσίες</Link>
          </h3>
          <ul>
            <li>ΟΕΜ νταλλακτικά</li>
            <li>Ανακαίνιση Αυτοκινήτου</li>
            <li>Easy Φανοποιείο</li>
            <li>Αυτοκίνητο Αντικατάστασης</li>
            <li>Επεξήγηση Χρεώσεων</li>
            <li>Εγγύηση Εργασιών</li>
            <li>Pick up & Delivery</li>
          </ul>
          <h3 className='footer-offers'>
            <Link to="/offers">Προσφορές</Link>
          </h3>
        </div>
        <div className="footer-contact-info">
          <img src={logo} className='footer-logo' alt="Logo Footer" />
          <a href="https://facebook.com" className='footer-social-media'>
            <FacebookIcon 
              className='footer-facebook-icon'
            />
            Facebook
          </a>
          <ul className='footer-links'>
            <li>
              <Link to="/">Αρχική</Link>
            </li>
            <li>
              <Link to="/contact-us">Επικοινωνία</Link>
            </li>
            <li>
              <Link to="/customer-comments">Σχόλια πελατών</Link>
            </li>
            {/* <li>
              <Link to="/financials">Οικονομικά Στοιχεία</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
}
 
export default Footer;