import { useState } from 'react';
import { CarouselData } from './CarouselData'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './carousel.css'

const Carousel = ({slides}) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  if(!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  return (
    <section className='carousel'>
      <ArrowBackIosIcon className='left-arrow' onClick={prevSlide} />
      <ArrowForwardIosIcon className='right-arrow' onClick={nextSlide} />
      {CarouselData.map((slide, index) => {
        return (
          <div className={index === current ? 'slide active' : 'slide'}  key={index}>
            {index === current && (
              <>
                <img src={slide.image} alt="slider" className="image-carousel" />
                <div className='carousel-text'>
                  <p className='slide-text'>{slide.text1}</p>
                  <p className='slide-text'>{slide.text2}</p>
                </div>
              </>
            )}
          </div>
        )
      })}
    </section>
  );
}
 
export default Carousel;

{/* // <div className="carousel">
//   <div className="carousel-text">
//     <p>Καλώς ήρθατε στη νέα μας ιστοσελίδα!</p>
//     <p>Τώρα είναι ακόμα πιο εύκολο να βρείτε και να εκμεταλλευτείτε τις προσφορές μας</p>
//   </div>
// </div> */}