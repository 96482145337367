import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import { carOffers } from '../../../utils/carOffers'

var settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const BrandOffer = () => {
  return (
    <div className='offers-brands'>
      <Slider {...settings}>
        {carOffers.map(image => (
          <div key={image.id} className="offer-item">
            <Link  to={{
              pathname: `/offers/${image.id}`,
              state: { id: image.id }
            }}>
              <img src={image.image} alt="" className='offers-image' />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}
 
export default BrandOffer;

