import Carousel from '../carousel/Carousel'
import HomepageBrands from '../brands/HomepageBrands'
import HomeText from '../homeText/HomeText'
import HomepageComments from '../homepageComments/HomepageComments';
import ContactSection from '../contactSection/ContactSection'
import { CarouselData } from '../carousel/CarouselData';

const HomeBody = () => {
  return (
    <>
      <Carousel 
        slides={CarouselData}
      />
      <HomepageBrands />
      <HomeText />
      <HomepageComments />
      <ContactSection />
    </>
  );
}
 
export default HomeBody;