import './hometext.css'

const HomeText = () => {
  return (
    <div className='hometext-section'>
      <div className="hometext-background">
        <h2>Ποιότητα αντιπροσωπείας με τιμές ελεύθερου συνεργείου</h2>
        <ul>
          <li>Δωρεάν έλεγχος 22 σημείων</li>
          <li>15% έκπτωση στα ανταλλακτικά εφόσον απαιτηθούν εργασίες</li>
          <li>Είσπραξη απευθείας από ασφαλιστικές</li>
          <li>Αυτοκίνητο αντικατάστασης</li>
          <li>Διευκόλυνση πληρωμής με άτοκες δόσεις</li>
          <li>1 έτος εγγύηση εργασιών</li>
        </ul>
      </div>
    </div>
  );
}
 
export default HomeText;