import Offers from '../screens/offers/Offers';
import CarOffers from '../screens/offers/CarOffers'
import HomeBody from './screenComponents/homeBody/HomeBody'
import CarSelling from '../screens/sellCar/CarSelling'
import AboutCompany from '../screens/about/AboutCompany'
import WhyUs from '../screens/whyUs/WhyUs'
import WorkShops from '../screens/workShops/WorkShops';
import CustomerComments from '../screens/comments/CustomerComments'
import Services from '../screens/companyServices/Services'
import Contact from '../screens/contact/Contact'
import FinancialData from '../screens/financial/FinancialData'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

const Routers = () => {
  return (
      <Switch>
        <Route path="/" exact component={HomeBody} />
        <Route path="/services" component={Services} />
        <Route path="/offers" exact component={Offers} />
        <Route path="/offers/:id" component={CarOffers}/>
        <Route path="/car-selling"component={CarSelling} />
        <Route path="/about-company"component={AboutCompany} />
        <Route path='/why-us' component={WhyUs} />
        <Route path='/diktyo-synergeion' component={WorkShops} />
        <Route path="/customer-comments"component={CustomerComments} />
        <Route path="/contact-us" component={Contact} />
        <Route path="/financials" component={FinancialData} />
      </Switch>
  );
}

export default Routers;