import Navbar from '../../components/screenComponents/navbar/Navbar';
import Body from '../../components/Body'
import Footer from '../../components/screenComponents/footer/Footer'
import Copyright from  '../../components/screenComponents/footer/Copyright'
import { useEffect } from 'react';

const Home = () => {

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <div>
      <Navbar />
      <Body />
      <Footer />
      <Copyright />
    </div>
  );
}

export default Home;