import Carousel from '../../components/screenComponents/carousel/Carousel'
import './offersScreen.css'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { carOffersLinks } from '../../utils/carOffersLinks';
import { CarouselData } from '../../components/screenComponents/carousel/CarouselData';

const Offers = () => {

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <>
      <Carousel
        slides={CarouselData}
      />
      <div className='offers-screen'>
        <h2>Προσφορές Dynamotors</h2>
        <p>Κάντε κλικ στη μάρκα που σας ενδιαφέρει για να δείτε τις προσφορές μας</p>
        <div className='link-for-offers'>
          <ul className="links-offers-list">
            {carOffersLinks.map(offer => (
              <Link key={offer.id} to={{
                pathname: `/offers/${offer.id}`,
                state: { id: offer.id }
              }}>
                <li className='links-offers-items'>
                  {offer.link}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
 
export default Offers;