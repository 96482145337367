import './App.css'
import Main from './components/Main';
import '@fontsource/roboto';

function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;
