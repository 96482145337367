import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import './comments.css'

const HomepageComments = () => {
  return (
    <div className='testimonial-background'>
      <div className="testimonials-section">
        <article className='testimonial'>
          <p className='testimonial-text'>Είμαι πελάτης του φανοποιείου της Dynamotors . Η εμπερία μου μετά το τρακάρισμα ήταν ιδιαίτερα θετική, 
            καθώς οι άνθρωποι υλοποίησαν ακριβώς όσα υπόσχονται. Μου έδωσαν αυτοκίνητο αντικατάστασης και εισέπραξαν τα χρήματα κατευθείαν 
            από την ασφαλιστική εταιρία. Ο μόνος χρόνος που έχασα ήταν για μια εξουσιοδότηση στο ΚΕΠ. Η επισκευή διήρκησε μόνο μερικές μέρες, 
            ενώ όσον αφορά την ποιότητα δεν μπορείς να διακρίνεις εάν έγινε ποτέ ατύχημα. Το τρακάρισμα δεν αποτελεί πλέον πρόβλημα, 
            αρκεί να είσαι ασφαλισμένος !!!
          </p>
          <div className='testimonial-author'>
            <p>Γ. Αγγέλου</p>
            <time dateTime="2017-08-11T12:00:00Z">11/08/2017</time>
          </div>
        </article>
        <article className='testimonial'>
          <p className='testimonial-text'>Είμαι κάτοχος ενός Citroen C4 και η διάγνωση της βλάβης ήταν αντικατάσταση εγκεφάλου. Έμεινα απόλυτα ευχαριστημένος από την 
            γρήγορη εξυπηρέτηση και τις χαμηλές τιμές. Το συνεργείο είναι πολύ οργανωμένο και παρόλη την αρκετή δουλειά που έχει 
            το συνιστώ ανεπιφύλακτα.
          </p>
          <div className='testimonial-author'>
            <p>Μανώλης Δημήτρης</p>
            <time dateTime="2016-07-19T12:00:00Z">19/07/2016</time>
          </div>
        </article>
        <article className='testimonial'>
          <p className='testimonial-text'>Γνώριζα ότι αυτή η εταιρία εξειδικεύεται στα OPEL και δεν περίμενα τόσο καλή και γρήγορη εξυπηρέτηση για το Audi A3 που έχω. 
            Και πλήρωσα για αλλαγή λαδιών και μικρό Service μόνο 49 Ευρώ!!!  Επίσης έλυσαν το ηλεκτρικό πρόβλημα που είχα (θερμοστάτης) 
            και που μέχρι τότε ήταν άλυτο.
            Η τιμή ήταν πάνω από 50% χαμηλότερη από τον Audi dealer που το πήγαινα έως τώρα. Μου έδωσαν και όλα τα χρησιμοποιημένα ανταλλακτικά 
            σε μια σακούλα και μου είπαν ότι έχω εγγύηση εργασιών για 2 χρόνια.
          </p>
          <div className='testimonial-author'>
            <p>Πορίχης Δημήτρης</p>
            <time dateTime="2015-06-08T12:00:00Z">08/06/2015</time>
          </div>
        </article>
      </div>
      <Link to="/customer-comments" className="testimonials-more">
        Περισσότερα
        <ChevronRightIcon
          style={{marginTop: 1}}
        />
      </Link>
    </div>
  );
}
 
export default HomepageComments;