import { useState } from 'react';
import FeathersStore from "../../../FeathersStore";

const useForm = (setShowSuccess, setShowError, validate) => {
  const [values, setValues] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    let newValues = {
      ...values,
      [name]: value
    };
    setValues(newValues);
    if (Object.keys(errors).length !== 0){
      let validationErrors = validate(newValues);          
      setErrors(validationErrors);
    }
  };

  const clearInputs =() => {
    setValues(
      {
        fullName: '',
        phoneNumber: '',
        email: '',
        message: ''
      }
    );
    setErrors({});
  }

  const handleSubmit = async e => {
    try{
      let validationErrors = validate(values);          
      setErrors(validationErrors);
      if(Object.keys(validationErrors).length === 0){
        setIsSubmitting(true);   
        await FeathersStore.sendContactEmail(values.fullName, "", values.message, values.phoneNumber)
        setIsSubmitting(false);
        setShowSuccess(true); 
        clearInputs();
      }          
    }catch(err){
      console.log("err: ", err);
      setIsSubmitting(false);
      setShowError(true);
    }
  };

  return { handleChange, handleSubmit, values, errors, isSubmitting };
};

export default useForm;