import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useState } from 'react';
import logo from '../../images/logo.png';
import { Link } from 'react-router-dom'
import './navbar.css'
import { linksForOffers } from '../../../utils/linksForOffers'
 
const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [isActiveAboutUs, setIsActiveAboutUs] = useState(false);

  return (
    <nav className='navbar'>
      <div className='navbar-flex-desktop'>
        <Link to="/">
          <img src={logo} alt="Logo Dynamotors" className='logo' />
        </Link>
        <ul className='navbar-menu'>
          <li className='navbar-menu-item'>
            <Link to='/'>
              ΑΡΧΙΚΗ
            </Link>
          </li>
          <li className='navbar-menu-item'>
            <Link to='/services'>
              ΥΠΗΡΕΣΙΕΣ
            </Link>
          </li>
          <li className='dropdown navbar-menu-item'>
            <div className='navbar-li-flex'>
              <Link to='/offers'>
                ΠΡΟΣΦΟΡΕΣ
              </Link>
              <ArrowDropDownIcon
                className='navbar-icon'
              />
            </div>
            <ul className='navbar-dropdown-content'>
              {linksForOffers.map(offer => (
                <Link key={offer.id} to={{
                  pathname: `/offers/${offer.id}`,
                  state: { id: offer.id }
                }}>
                  <li className='dropdown-items'>
                    {offer.link}
                  </li>
                </Link>
              ))}
            </ul>
          </li>
          {/* <li className='navbar-menu-item'>
            <Link to='/car-selling'>
              ΜΕΤΑΧΕΙΡΙΣΜΕΝΑ
            </Link>
          </li> */}
          <li className='dropdown navbar-menu-item'>
            <div className='navbar-li-flex'>
              <Link to='/about-company'>
                ΕΤΑΙΡΙΑ
              </Link>
              <ArrowDropDownIcon
                className='navbar-icon'
              />
            </div>
            <ul className='navbar-dropdown-content'>
              <li className='dropdown-items'>
                <Link to='/about-company'>
                  Ποιοι Είμαστε
                </Link>
              </li>
              <li className='dropdown-items'>
                <Link to='/why-us'>
                  Γιατί Εμάς
                </Link>
              </li>
              <li className='dropdown-items'>
                <Link to='/diktyo-synergeion'>
                  Δίκτυο Συνεργείων
                </Link>
              </li>
            </ul>
          </li>
          <li className='navbar-menu-item'>
            <Link to='/customer-comments'>
              ΣΧΟΛΙΑ ΠΕΛΑΤΩΝ
            </Link>
          </li>
          <li className='navbar-menu-item'>
            <Link to='/contact-us'>
              ΕΠΙΚΟIΝΩΝΙΑ
            </Link>  
          </li>
          <li className='contact-phone navbar-menu-item'>
            <a href="tel:210 6107623">
              210 6107623
            </a>
          </li>
        </ul>
      </div>

      {/* Tablet - Mobile Responsive */}

      <div className='navbar-flex-responsive'>
        <Link to="/">
          <img src={logo} alt="Logo Dynamotors" className='logo-mobile' />
        </Link>
        <div className='mobile-contact-phone'>
          <a href="tel:210 6107623">
            210 6107623
          </a>
        </div>
        <div className="menu-wrap">
          <input type="checkbox" className="toggler" />
          <div className="hamburger"><div></div></div>
          <div className="hamburger-menu">
            <ul className='hamburger-navbar-menu'>
              <li className='hamburger-navbar-menu-item'>
                <Link to='/'>
                  ΑΡΧΙΚΗ
                </Link>
              </li>
              <li className='hamburger-navbar-menu-item'>
                <Link to='/services'>
                  ΥΠΗΡΕΣΙΕΣ
                </Link>
              </li>
              <li className={isActive ? 'hamburger-navbar-menu-item-isActive' : 'hamburger-dropdown hamburger-navbar-menu-item'}>
                <div className='navbar-li-flex'>
                  <Link to='/offers'>
                    ΠΡΟΣΦΟΡΕΣ
                  </Link>
                  <ArrowDropDownIcon
                    className='navbar-icon'
                    onClick={() => setIsActive(!isActive)}
                  />
                </div>
              </li>
              {isActive &&
                <ul className='hamburger-navbar-dropdown-content'>
                  {linksForOffers.map(offer => (
                    <Link key={offer.id} to={{
                      pathname: `/offers/${offer.id}`,
                      state: { id: offer.id }
                    }}>
                      <li className='hamburger-dropdown-items'>
                        {offer.link}
                      </li>
                    </Link>
                  ))}
                </ul>
              }
              <li className={isActiveAboutUs ? 'hamburger-navbar-menu-item-isActive' : 'hamburger-dropdown hamburger-navbar-menu-item'}>
                <div className='navbar-li-flex'>
                  <Link to='/about-company'>
                    ΕΤΑΙΡΙΑ
                  </Link> 
                    <ArrowDropDownIcon
                      className='navbar-icon'
                      onClick={() => setIsActiveAboutUs(!isActiveAboutUs)}
                    />
                </div>
              </li>
              {isActiveAboutUs &&
                <ul className='hamburger-navbar-dropdown-content'>
                  <li className='hamburger-dropdown-items'>
                    <Link to='/about-company'>
                      Ποιοι Είμαστε
                    </Link>
                  </li>
                  <li className='hamburger-dropdown-items'>
                    <Link to='/why-us'>
                      Γιατί Εμάς
                    </Link>
                  </li>
                  <li className='hamburger-dropdown-items'>
                    <Link to='/diktyo-synergeion'>
                      Δίκτυο Συνεργείων
                    </Link>
                  </li>
                </ul>
              }
              <li className='hamburger-navbar-menu-item'>
                <Link to='/customer-comments'>
                  ΣΧΟΛΙΑ ΠΕΛΑΤΩΝ
                </Link>
              </li>
              <li className='hamburger-navbar-menu-item'>
                <Link to='/contact-us'>
                  ΕΠΙΚΟIΝΩΝΙΑ
                </Link>  
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
 
export default Navbar;