import { useState } from 'react';
import OfferSidebar from './OfferSidebar';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { linksForOffers } from '../../utils/linksForOffers'
import CarOfferContactForm from './CarOfferContactForm';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const CarOffers = () => {

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  let match = useRouteMatch();
  const selectedOffer = linksForOffers[match.params.id];

  const [current, setCurrent] = useState(0);
  const length = linksForOffers[match.params.id].CarouselData.length;
 
  if(!Array.isArray(linksForOffers) || linksForOffers[match.params.id].CarouselData.length <= 0) {
    return null;
  }

  return (
    <>
      <section className='carousel'>
        <ArrowBackIosIcon className='left-arrow' onClick={prevSlide} />
        <ArrowForwardIosIcon className='right-arrow' onClick={nextSlide} />
        {linksForOffers[match.params.id].CarouselData.map((slide, index) => {
          return (
            <div className={index === current ? 'slide active' : 'slide'}  key={index}>
              {index === current && (
                <>
                  <img src={slide.image} alt="slider" className="image-carousel" />
                  <div className='carousel-text'>
                    <p className='slide-text'>{slide.text1}</p>
                    <p className='slide-text'>{slide.text2}</p>
                  </div>
                </>
              )}
            </div>
          )
        })}
      </section>
      <div className='car-offers-section'>
        <div className="main-offer-section">
        <div className="car-offer">
          <p className="internet-offer">{selectedOffer.internetOffer}</p>
          <div className="car-offer-header">
            <img className="car-offer-image" src={selectedOffer.image} alt="OPEL" />
            <div className="header-info">
              <div className="card">
                <div className='face front'>
                  <p>{selectedOffer.seeCodeOffer}</p>
                </div>
                <div className="face back">
                  <p>{selectedOffer.codeOffer}</p>
                </div>
              </div>
            </div>
          </div>
          <h2>{selectedOffer.serviceTitle} <span className="internet-offer-price">{selectedOffer.serviceTitleSpan}</span></h2>
          <div className="car-offer-body">
            <h3>{selectedOffer.secondTitle}</h3>
            <p>{selectedOffer.paragraph1}</p>
            <ul className="car-offer-service-items">
              <li><span className="main-color">{selectedOffer.li1Span}</span>{selectedOffer.li1}</li>
              <li><span className="main-color">{selectedOffer.li2Span}</span>{selectedOffer.li2}</li>
              <li><span className="main-color">{selectedOffer.li3Span}</span>{selectedOffer.li3}</li>
              <li><span className="main-color">{selectedOffer.li4Span}</span>{selectedOffer.li4}</li>
              <li><span className="main-color">{selectedOffer.li5Span}</span>{selectedOffer.li5}</li>
              <li><span className="main-color">{selectedOffer.li6Span}</span>{selectedOffer.li6}</li>
            </ul>
            <p>{selectedOffer.paragraph2}</p>
            <p><span className="main-color">{selectedOffer.paragraph3Span}</span>{selectedOffer.paragraph3}</p>
            <p><span className="main-color">{selectedOffer.paragraph4Span}</span>{selectedOffer.paragraph4}</p>
            <p><span className="main-color">{selectedOffer.paragraph5Span}</span>{selectedOffer.paragraph5}</p>
            <p><span className="main-color">{selectedOffer.paragraph6Span}</span>{selectedOffer.paragraph6}</p>
            <p><span className="main-color">{selectedOffer.paragraph7Span}</span>{selectedOffer.paragraph7}</p>
            <p><span className="main-color">{selectedOffer.paragraph8Span}</span>{selectedOffer.paragraph8}</p>
          </div>
        </div>
        <h3 className="call-us-offer">Καλέστε μας τώρα ή στείλτε μας το μήνυμά σας για να επωφεληθείτε της προσφοράς</h3>
        <div className="car-offer-contact-us">
          <CarOfferContactForm />
        </div>
      </div>
        <OfferSidebar />
      </div>
    </>
  );
}
 
export default CarOffers;