import OPEL from '../components/images/OPEL.jpeg'
import BMW from '../components/images/BMW.jpeg'
import MINI from '../components/images/MINI.jpeg'
import VOLKSWAGEN from '../components/images/VOLKSWAGEN.jpeg'
import AUDI from '../components/images/AUDI.jpeg'
import MERCEDES from '../components/images/MERCEDES.jpeg'
import SMART from '../components/images/SMART.jpeg'
import FORD from '../components/images/FORD.jpeg'
import SEAT from '../components/images/SEAT.jpeg'
import SKODA from '../components/images/SKODA.jpeg'
import CITROEN from '../components/images/CITROEN.jpeg'

export const carOffers = [
  {
    id: 0,
    link: 'OPEL',
    internetOffer: 'Προσφορά Internet', 
    image: OPEL, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMOPEL1017',
    serviceTitle: 'Service Γενικό για OPEL με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: 'Εξουσιοδοτημένη μονάδα OPEL',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: '4 λίτρα λάδι GM',
    li5Span: '→',
    li5: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Διαγνωστικό Global Connect συνδεδεμένο on-line με Adam Opel AG και εκπαιδευμένοι τεχνικοί',
    paragraph4Span: '* ',
    paragraph4: 'Η προσφορά ισχύει για αυτοκίνητα έως 2000 κ.εκ.',
  },
  { 
    id: 1,
    link: 'BMW',
    internetOffer: 'Προσφορά Internet', 
    image: BMW, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMBMW1017',
    serviceTitle: 'Γενικό Service BMW μόνο ',
    serviceTitleSpan: '160 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου λαδιού',
    li3Span: '→',
    li3: 'Αλλαγή υγρών φρένων',
    li4Span: '→',
    li4: 'Αλλαγή 4 μπουζί',
    li5Span: '→',
    li5: '5 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 160 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr.',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Διαγνωστικά μηχανήματα ISTA/P και key reader',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
    paragraph7Span: '* ',
    paragraph7: 'Ηλεκτρονικό βιβλίο Service με σύνδεση με BMW AG',
    paragraph8Span: '* ',
    paragraph8: 'Η προσφορά ισχύει για τετρακύλινδρους κινητήρες έως 2000 κ.εκ.',
  },
  {
    id: 2,
    link: 'MINI',
    internetOffer: 'Προσφορά Internet', 
    image: MINI, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMMINI1017',
    serviceTitle: 'Γενικό Service Mini μόνο ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία , ΦΠΑ και οι εξής υπηρεσίες και ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου λαδιού',
    li3Span: '→',
    li3: 'Αλλαγή υγρών φρένων',
    li4Span: '→',
    li4: 'Αλλαγή 4 μπουζί',
    li5Span: '→',
    li5: '5 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 200 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr.',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Διαγνωστικά μηχανήματα ISTA/P και key reader',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
    paragraph7Span: '* ',
    paragraph7: 'Ηλεκτρονικό βιβλίο Service με σύνδεση με BMW AG',
    paragraph8Span: '* ',
    paragraph8: 'Η προσφορά ισχύει για τετρακύλινδρους κινητήρες',
  },
  {
    id: 3,
    link: 'VOLKSWAGEN',
    internetOffer: 'Προσφορά Internet', 
    image: VOLKSWAGEN, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMVW1017',
    serviceTitle: 'Service Γενικό για Volkswagen Group (VW, Audi, SEAT, Skoda) με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: '4 λίτρα λάδι Castrol Edge 5 - 30 συνθετικό',
    li5Span: '→',
    li5: 'Αλλαγή μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Διαγνωστικό Erwin συνδεδεμένο on-line με Group VAG και εκπαιδευμένοι τεχνικοί.',
    paragraph5Span: '* ',
    paragraph5: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    id: 4,
    link: 'AUDI',
    internetOffer: 'Προσφορά Internet', 
    image: AUDI, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMVWAUDI1017',
    serviceTitle: 'Service Γενικό για Volkswagen Group (VW, Audi, SEAT, Skoda) με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή υγρών φρένων',
    li4Span: '→',
    li4: 'Αλλαγή φίλτρου λαδιού',
    li5Span: '→',
    li5: 'Αλλαγή μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Διαγνωστικό Erwin συνδεδεμένο on-line με Group VAG και εκπαιδευμένοι τεχνικοί.',
    paragraph5Span: '* ',
    paragraph5: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    id: 5,
    link: 'MERCEDES',
    internetOffer: 'Προσφορά Internet', 
    image: MERCEDES, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMMERCEDES0218',
    serviceTitle: 'Γενικό Service Mercedes μόνο ',
    serviceTitleSpan: '160 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και οι εξής υπηρεσίες και ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου καμπίνας',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου βενζίνης',
    li4Span: '→',
    li4: 'Αλλαγή φίλτρου λαδιού',
    li5Span: '→',
    li5: 'Αλλαγή μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li6Span: '→',
    li6: '5 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 160 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr.',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Εξειδικευμένα διαγνωστικά μηχανήματα',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
    paragraph7Span: '* ',
    paragraph7: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
    paragraph8Span: '* ',
    paragraph8: 'Η προσφορά ισχύει για αυτοκίνητα έως 2000 κ.εκ.',
  },
  {
    id: 6,
    link: 'SMART',
    internetOffer: 'Προσφορά Internet', 
    image: SMART, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMSMART0218',
    serviceTitle: 'Γενικό Service Smart μόνο ',
    serviceTitleSpan: '120 Ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου καμπίνας',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου βενζίνης',
    li4Span: '→',
    li4: 'Αλλαγή φίλτρου λαδιού',
    li5Span: '→',
    li5: 'Αλλαγή μπουζί',
    li6Span: '→',
    li6: '3 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 120 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Εξειδικευμένα διαγνωστικά μηχανήματα',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
  },
  {
    id: 7,
    link: 'FORD',
    internetOffer: 'Προσφορά Internet', 
    image: FORD, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMFORD0218',
    serviceTitle: 'Service Γενικό Ford μονο ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li41Span: '→',
    li4: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li5Span: '→',
    li5: '4 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 200 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Εξειδικευμένα διαγνωστικά μηχανήματα',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
  },
  {
    id: 8,
    link: 'SEAT',
    internetOffer: 'Προσφορά Internet', 
    image: SEAT, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMSEATSKODA1017',
    serviceTitle: 'Service Γενικό για Volkswagen Group (VW, Audi, SEAT, Skoda) με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li5Span: '→',
    li5: '4 λίτρα λάδι Castrol Edge 5 - 30 συνθετικό',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Διαγνωστικό Erwin συνδεδεμένο on-line με Group VAG και εκπαιδευμένοι τεχνικοί.',
    paragraph5Span: '* ',
    paragraph5: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    id: 9,
    link: 'SKODA',
    internetOffer: 'Προσφορά Internet', 
    image: SKODA, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMSEATSKODA1017',
    serviceTitle: 'Service Γενικό για Volkswagen Group (VW, Audi, SEAT, Skoda) με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li5Span: '→',
    li5: '4 λίτρα λάδι Castrol Edge 5 - 30 συνθετικό',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Διαγνωστικό Erwin συνδεδεμένο on-line με Group VAG και εκπαιδευμένοι τεχνικοί.',
    paragraph5Span: '* ',
    paragraph5: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    id: 10,
    link: 'CITROEN',
    internetOffer: 'Προσφορά Internet', 
    image: CITROEN, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMCITROEN1017',
    serviceTitle: 'Service Γενικό για CITROEN μόνο ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li5Span: '→',
    li5: '4 λίτρα λάδι TOTAL',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
]