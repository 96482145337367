import React, {useState} from 'react';
import validate from '../contact/form/validateInfo';
import useForm from '../contact/form/useForm';
import { CircularProgress } from '@material-ui/core';
import SmallAlert from "../../components/screenComponents/modals/smallAlert";

const CarOfferContactForm = ({submitForm}) => {

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const { handleChange, handleSubmit, values, errors, isSubmitting } = useForm(
    setShowSuccess,
    setShowError,
    validate
  );

  return (
    <>
      <form  className='form' noValidate>
        <div className='form-inputs'>
          <label className='form-label'>Το όνοματεπώνυμό σας<span className="asterisk"> *</span></label>
          <div className='handle-inputs'>
            <input
              className='form-input border-style'
              type='text'
              name='fullName'
              value={values.fullName}
              onChange={handleChange}
            />
            {errors.fullName && <span className='error-message-car-offer'>{errors.fullName}</span>}
          </div>
        </div>

        <div className='form-inputs'>
          <label className='form-label'>Τηλέφωνο επικοινωνίας<span className="asterisk"> *</span></label>
          <div className='handle-inputs'>
            <input
              className='form-input border-style'
              type='text'
              name='phoneNumber'
              value={values.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && <span className='error-message-car-offer'>{errors.phoneNumber}</span>}
          </div>
        </div>

        <div className='form-inputs'>
          <label className='form-label'>Η διεύθυνση email σας</label>
          <div className='handle-inputs'>
            <input
              className='form-input border-style'
              type='email'
              name='email'
              value={values.email}
              onChange={handleChange}
              />
          </div>
        </div>

        <div className='form-inputs'>
          <label className='form-label'>Μήνυμα<span className="asterisk"> *</span></label>
          <div className='handle-inputs'>
            <textarea 
              className='form-input'
              style={{border: '1px solid #aaa'}}
              name="message" 
              value={values.message}
              onChange={handleChange}> 
            </textarea>
            {errors.message && <span className='error-message-car-offer'>{errors.message}</span>}
          </div>
        </div>       
      </form> 
      <button className='form-btn' onClick = {handleSubmit} type='submit'>
        Αποστολή μηνύματος
        {isSubmitting && 
          <CircularProgress 
            color="inherit" 
            style={{ marginLeft: 6 }} 
            size={'1.5rem'}            
          />                  
        }
      </button>
      {/*showSuccess && window.alert(`Επιτυχής αποστολή email `)*/}
      
      <SmallAlert
        open={showSuccess}
        styling="info"
        heading={"Λάβαμε το μήνυμά σας!"}
        onClose={() => setShowSuccess(false)}
        message1={"Σύντομα ένας εκπρόσωπός μας θα επικοινωνήσει μαζί σας."}
        message2={"Ευχαριστούμε πολύ για την επικοινωνία."}  
      />
      <SmallAlert
        open={showError}
        styling="danger"
        heading={"Λανθασμένη αποστολή email"}
        onClose={() => setShowError(false)}
        message1={"Προσπαθείστε ξανά αργότερα"} /> 
      
    </>
  )
}
 
export default CarOfferContactForm;