
import io from 'socket.io-client'; 
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';

 /**
* Simple wrapper for feathers
*/
class FeathersStore{
  API_URL = 'https://sites.appdate.gr'; 
  DEFAULT_EMAIL = "defaultUser@gmail.com";
  DEFAULT_PSW = ")j~nKj/,N}N6,8&cVVV#G!=F*y";
  MAILGUN_EMAIL = "appdate.gr@gmail.com";
  RECEIVER_EMAIL = "info.appdate@gmail.com"

  connect = async () => {
    this.app = feathers();                     
    this.socket = io(this.API_URL); 
  
    this.app
      .configure(socketio(this.socket))        
      .configure( auth({            
          storage: window.localStorage // To store our accessToken
    }))

    this.app.io.on('connect', async () => {      
      try{
        const auth = await this.app.reAuthenticate();        
        console.log('authenticated after reconnection');
      }catch(error) {
        await this.defaultLogin();    
        console.log('error authenticating after reconnection', error);
      }
    });    

    this.app.io.on('disconnect', () => {
      console.log('disconnected');      
    });
  } 

  defaultLogin = async () => {        
    return this.app.authenticate({
        strategy: 'local',
        email: this.DEFAULT_EMAIL,
        password: this.DEFAULT_PSW
      });
  };
   
  logout = async() => {
     return this.app.logout();
   }   

  sendContactEmail = async( email, firstname, lastname, message, phone) => {  
      
    let htmlContent =  `
      <html>
      <head>
      <style>
      body{  
        background-color:#c8d7e1;        
      }
      h2{
        background-color:hsl(217, 10%, 25%);
        color:#f1ebff;
        text-align: center;
      }        
      .message-container{
        border: 1px solid black;
        padding: 15px;
        text-align: left; 
        background-color: #f1f1c1;
      }
      .container{
        margin-top: 2vw;         
        padding: 2vw;
        border: 1px solid black;          
        font-size:16px;
        width: 60%;          
      }
      @media screen and (max-width: 992px) {
        .container{
          width: 95%;
        }
      }
      </style>
      </head>
      <body> 
      <div class="container">       
        <h2>Νέο μήνυμα από το site Dynamotors</h2>
        <div class="message-container">
          <p>Ο χρήστης ${firstname} ${lastname} ,</p>
          <p>  με email ${email} ${phone ? `και τηλέφωνο: ${phone}` : ""} ,</P>
          <p>έστειλε το εξής μήνυμα:   </p>
          <div>
                 ${message}
          </div>
        </div>
      </div>
      </body
    </html>`
   
      const emailToSend = {          
        from: `${this.MAILGUN_EMAIL}`,
        to: `${this.RECEIVER_EMAIL}`,
        subject: 'Μήνυμα από τη φόρμα επικοινωνίας του e-shop',
        html: htmlContent 
     };    
    return await this.app.service('emails').create(emailToSend);
    
  }
  
 }

export default new FeathersStore()