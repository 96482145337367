import Carousel from '../../components/screenComponents/carousel/Carousel';
import './whyUs.css'
import { useEffect } from 'react';
import { CarouselData } from '../../components/screenComponents/carousel/CarouselData';

const WhyUs = () => {

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <>
      <Carousel 
        slides={CarouselData}
      />
      <div className='why-us-section'>
        <h2>Γιατί εμάς</h2>
        <ul className='why-us-section-list'>
          <li>Ποιοτική εργασία</li>
          <p>Εξειδικευμένοι τεχνίτες σε κάθε μάρκα επισκευάζουν το όχημά σας . Κάθε τεχνίτης εκπαιδεύεται τουλάχιστον 2 ημέρες το έτος.</p>
          <li>Χαμηλές τιμές</li>
          <p>Εχουμε προσπαθήσει να έχουμε χαμηλά κόστη με ιδιόκτητα κτήρια και πληθώρα εργαλείων ώστε να αυξάνεται η παραγωγικότητα και έτσι μπορούμε να σας προσφέρουμε τις 
            καλύτερες τιμές της αγοράς.
          </p>
          <li>Ο πελάτης στο επίκεντρο</li>
          <p>Υπαρξη τεχνικών συμβούλων και γραμματειακής υποστήριξης, ώστε να έχετε άμεση και γρήγορη εξυπηρέτηση από ανθρώπους που θα ακούσουν το πρόβλημά σας. 
            Στη συνέχεια γίνεται διάγνωση με εξειδικευμένους υπολογιστές για κάθε μάρκα και σας δίνουμε προσφορά για την επισκευή. Κατά την παράδοση του οχήματός 
            σας θα σας εξηγήσουμε επακριβώς τις χρεώσεις ώστε να συμφωνούν με την προσφορά μας.
          </p>
          <li>Αξιοπιστία</li>
          <p>Η παρουσία μας από το 1981 με χιλιάδες ικανοποιημένους πελάτες εγγυάται την ποιότητα της επισκευής σας. Έχουμε πρακτικά μηδενική επιστροφή ενός αυτοκινήτου 
            για το ίδιο πρόβλημα. Δίνουμε εγγύηση 1 έτους για μηχανικές εργασίες και 10 ετών για εργασίες βαφής.
          </p>
        </ul>
      </div>
    </>
  );
}
 
export default WhyUs;