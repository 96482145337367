const Copyright = () => {
  return (
    <div className='footer-copyright'>
      <p>© Dynamotors 2021, All Rights Reserved</p>
      <p>Σχεδιασμός & Ανάπτυξη Ιστοσελίδας <a href="https://www.appdate.gr/home">AppDate</a>
      </p>
    </div>
  );
}

export default Copyright;