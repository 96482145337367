import React, {useEffect} from 'react';
import Home from '../screens/home/Home'
import { BrowserRouter as Router } from 'react-router-dom';
import FeathersStore from "../FeathersStore";

const Main = () => {

  useEffect(() => {    
    connectToServer();      
  }, []);

  const connectToServer = async() => {
    try{
      await FeathersStore.connect(); 
      await FeathersStore.defaultLogin();
    }catch(error){
      window.alert(`Λανθασμένο login: ${error.message}`)
    };   
  }

  return (
    <Router>
      <div className="App">
        <Home />
      </div>
    </Router>
  );
}
 
export default Main;