import { useState, useEffect } from 'react';
import Carousel from '../../components/screenComponents/carousel/Carousel'
import PopupImage from './PopupImage';
import one from '../../components/images/financial/2015_1.PNG' 
import two from '../../components/images/financial/2015_2.PNG'
import three from '../../components/images/financial/2016_1.PNG'
import four from '../../components/images/financial/2016_2.PNG'
import five from '../../components/images/financial/2017.PNG'
import six from '../../components/images/financial/2018.PNG'
import seven from '../../components/images/financial/2019_1.PNG'
import eight from '../../components/images/financial/2019_2.PNG'
import nine from '../../components/images/financial/2020_1.PNG'
import ten from '../../components/images/financial/2020_2.PNG'
import eleven from '../../components/images/financial/2021_1.PNG'
import twelve from '../../components/images/financial/2021_2.PNG'

import { CarouselData } from '../../components/screenComponents/carousel/CarouselData';
import './financial.css'

const FinancialData = ({setTrigger}) => {
  const [buttonPopup1, setButtomPopup1] = useState(false);
  const [buttonPopup2, setButtomPopup2] = useState(false);
  const [buttonPopup3, setButtomPopup3] = useState(false);
  const [buttonPopup4, setButtomPopup4] = useState(false);
  const [buttonPopup5, setButtomPopup5] = useState(false);
  const [buttonPopup6, setButtomPopup6] = useState(false);
  const [buttonPopup7, setButtomPopup7] = useState(false);
  const [buttonPopup8, setButtomPopup8] = useState(false);
  const [buttonPopup9, setButtomPopup9] = useState(false);
  const [buttonPopup10, setButtomPopup10] = useState(false);
  const [buttonPopup11, setButtonPopup11] = useState(false);
  const [buttonPopup12, setButtonPopup12] = useState(false);

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <>
      <Carousel
        slides={CarouselData}
      />
      <div className='financial-section'>
        <h2>Οικονομικά στοιχεία</h2>
        <h3>Κάντε κλίκ στην χρονιά που θέλετε ώστε να δείτε τα ανάλογα οικονομικά στοιχεία</h3>
        <div className='financial-data-options'>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup1(true)}>2015_1</button>
            <PopupImage trigger={buttonPopup1} setTrigger={setButtomPopup1}>
              <img src={one} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup2(true)}>2015_2</button>
            <PopupImage trigger={buttonPopup2} setTrigger={setButtomPopup2}>
              <img src={two} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup3(true)}>2016_1</button>
            <PopupImage trigger={buttonPopup3} setTrigger={setButtomPopup3}>
              <img src={three} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup4(true)}>2016_2</button>
            <PopupImage trigger={buttonPopup4} setTrigger={setButtomPopup4}>
              <img src={four} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup5(true)}>2017</button>
            <PopupImage trigger={buttonPopup5} setTrigger={setButtomPopup5}>
              <img src={five} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup6(true)}>2018</button>
            <PopupImage trigger={buttonPopup6} setTrigger={setButtomPopup6}>
              <img src={six} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup7(true)}>2019_1</button>
            <PopupImage trigger={buttonPopup7} setTrigger={setButtomPopup7}>
              <img src={seven} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup8(true)}>2019_2</button>
            <PopupImage trigger={buttonPopup8} setTrigger={setButtomPopup8}>
              <img src={eight} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup9(true)}>2020_1</button>
            <PopupImage trigger={buttonPopup9} setTrigger={setButtomPopup9}>
              <img src={nine} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtomPopup10(true)}>2020_2</button>
            <PopupImage trigger={buttonPopup10} setTrigger={setButtomPopup10}>
              <img src={ten} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtonPopup11(true)}>2021_1</button>
            <PopupImage trigger={buttonPopup11} setTrigger={setButtonPopup11}>
              <img src={eleven} alt="" />
            </PopupImage>
          </div>
          <div>
            <button className='btn-trigger' onClick={() => setButtonPopup12(true)}>2021_2</button>
            <PopupImage trigger={buttonPopup12} setTrigger={setButtonPopup12}>
              <img src={twelve} alt="" />
            </PopupImage>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default FinancialData;