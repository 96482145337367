import Carousel from '../../components/screenComponents/carousel/Carousel';
import './aboutCompany.css'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { CarouselData } from '../../components/screenComponents/carousel/CarouselData';

const AboutCompany = () => {

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <>
      <Carousel
        slides={CarouselData}
      />
      <div className='about-company-section'>
        <div className='about-us-text'>
          <h2>Ποιοι είμαστε</h2>
          <p className='about-us-text-paragraphs'>Η εταιρεία <span className='about-us-text-links'>Dynamotors</span> ιδρύθηκε το 1981 ως αντιπροσωπεία αυτοκινήτων. Στη συνέχεια και μετά από μια επιτυχημένη 
            πορεία πολλών χρόνων και τη δημιουργία χιλιάδων πελατών, μετεξελίχθηκε σε ένα δίκτυο τριών συνεργείων και ενός φανοποιείου 
            και ασχολείται με σχεδόν όλες τις μάρκες αυτοκινήτων.
          </p>
          <p>Σε εμάς μπορείτε να επισκευάσετε το αυτοκίνητό σας με χαμηλό κόστος και ποιότητα εφάμιλλη της αντιπροσωπείας.</p>
          <p className='about-us-text-paragraphs'>Εξάλλου γι’ αυτό το λόγο δίνουμε εγγύηση <strong>1 έτους</strong> για τις μηχανικές επισκευές και <strong>10 ετών</strong> για τις βαφές.</p>
          <p className='about-us-text-paragraphs'>Στη σελίδα μας μπορείτε να βρείτε <span><Link className='about-us-text-links' to='/offers'>προσφορές</Link></span> για όλες τις μάρκες που εξυπηρετούμε.</p>
        </div>
        <div className="about-us-history-text">
          <h3>ΣΥΝΤΟΜΟ ΙΣΤΟΡΙΚΟ</h3>
          <p>Η DYNAMOTORS ιδρύθηκε το 1981 ως εισαγωγική Αντιπροσωπεία της OPEL. Ήταν η πρώτη «κάθετη» μονάδα στην Ελλάδα, συστεγάζοντας 
            σε ένα ειδικά διαμορφωμένο κτίριο πωλήσεις αυτοκινήτων, συνεργείο και αποθήκη ανταλλακτικών. Η πρωτοποριακή διάσταση της εταιρείας 
            δεν περιορίστηκε μόνο εκεί: Διαρκείς επενδύσεις σε τεχνολογία και εκπαίδευση προσωπικού, νέες μονάδες και η ανάγκη για διαρκή 
            βελτίωση οδήγησαν την εταιρεία στην επιτυχημένη πορεία που περιγράφεται παρακάτω . Πιο αναλυτικά οι σταθμοί της εταιρείας χρονολογικά:
          </p>
        </div>
        <ul className='about-us-date-list'>
          <li><strong><span>1981</span></strong> ίδρυση DYNAMOTORS A.E.</li>
          <li><strong><span>1990</span></strong> η εταιρεία ξεπερνά τις πωλήσεις 1.000 αυτοκινήτων το χρόνο</li>
          <li><strong><span>1995</span></strong> ίδρυση καταστημάτων στις πόλεις Πάτρα, Πύργο, Ζάκυνθο</li>
          <li><strong><span>2001</span></strong> η εταιρεία ξεπερνά τις πωλήσεις 3.000 αυτοκινήτων το χρόνο</li>
          <li><strong><span>2002</span></strong> έναρξη συνεργασίας με FIAT Group ως αντιπροσωπεία στην Αττική</li>
          <li><strong><span>2004</span></strong> η εταιρεία ανοίγει το 15ο υποκατάστημά της</li>
          <li><strong><span>2005</span></strong> έναρξη συνεργασίας με CITROEN και CHEVROLET ως αντιπροσωπεία στην Αττική</li>
          <li><strong><span>2006</span></strong> 19 καταστήματα και 5.000 πωλήσεις αυτοκινήτων</li>
          <li><strong><span>2010</span></strong> Προσαρμογή της εταιρίας στην κρίση με μείωση καταστημάτων και έμφαση στο Service</li>
          <li><strong><span>2012</span></strong> Δημιουργία πρωτοποριακής αλυσίδας Service - Φανοποιείων</li>
          <li><strong><span>2016</span></strong> Λειτουργία φανοποιείου Car Point στο Μαρούσι</li>
        </ul>
      </div>
    </>
  );
}
 
export default AboutCompany;