import OPEL from '../components/images/OPEL.jpeg'
import OpelOffer1 from '../components/images/offer-images/opel_offer1.jpg'
import OpelOffer2 from '../components/images/offer-images/opel_offer2.jpg'
import OpelOffer3 from '../components/images/offer-images/opel_offer3.jpg'
import BMW from '../components/images/BMW.jpeg'
import BmwOffer1 from '../components/images/offer-images/bmw_offer1.jpg'
import BmwOffer2 from '../components/images/offer-images/bmw_offer2.jpg'
import BmwOffer3 from '../components/images/offer-images/bmw_offer3.jpg'
import MINI from '../components/images/MINI.jpeg'
import MiniOffer1 from '../components/images/offer-images/mini_offer1.jpg'
import MiniOffer2 from '../components/images/offer-images/mini_offer2.jpg'
import MiniOffer3 from '../components/images/offer-images/mini_offer3.jpg'
import VOLKSWAGEN from '../components/images/VOLKSWAGEN.jpeg'
import VolkswagenOffer1 from '../components/images/offer-images/VW_offer1.jpg'
import VolkswagenOffer2 from '../components/images/offer-images/VW_offer2.jpg'
import AUDI from '../components/images/AUDI.jpeg'
import AudiOffer1 from '../components/images/offer-images/audi_offer1.jpg'
import MERCEDES from '../components/images/MERCEDES.jpeg'
import MercedesOffer1 from '../components/images/offer-images/mercedes_offer1.jpg'
import MercedesOffer2 from '../components/images/offer-images/mercedes_offer2.jpg'
import MercedesOffer3 from '../components/images/offer-images/mercedes_offer3.jpg'
import SMART from '../components/images/SMART.jpeg'
import SmartOffer1 from '../components/images/offer-images/smart_offer1.jpg'
import SmartOffer2 from '../components/images/offer-images/smart_offer2.jpg'
import SmartOffer3 from '../components/images/offer-images/smart_offer3.jpg'
import FORD from '../components/images/FORD.jpeg'
import FordOffer1 from '../components/images/offer-images/ford_offer1.jpg'
import FordOffer2 from '../components/images/offer-images/ford_offer2.jpg'
import FordOffer3 from '../components/images/offer-images/ford_offer3.jpg'
import SEAT from '../components/images/SEAT.jpeg'
import SeatOffer1 from '../components/images/offer-images/seat_offer1.jpg'
import SKODA from '../components/images/SKODA.jpeg'
import SkodaOffer1 from '../components/images/offer-images/skoda_offer1.jpg'
import CITROEN from '../components/images/CITROEN.jpeg'
import CitroenOffer1 from '../components/images/offer-images/citroen_offer1.jpg'
import CitroenOffer2 from '../components/images/offer-images/citroen_offer2.jpg'
import CitroenOffer3 from '../components/images/offer-images/citroen_offer3.jpg'
import RestBrands from '../components/images/ipoloipes_markes.jpeg'
import carousel1 from '../components/images/carousel_1.jpg'
import carousel2 from '../components/images/carousel_2.jpg'
import carousel3 from '../components/images/carousel_3.jpg'
import carousel4 from '../components/images/carousel_4.jpg'
import carousel5 from '../components/images/carousel_5.jpg'

export const linksForOffers = [
  {
    CarouselData: 
    [
      {
        image: OpelOffer1,
        text1: 'Εξουσιοδοτημένη μονάδα OPEL',
        text2: 'από το 1981',
      },
      {
        image: OpelOffer2,
        text1: 'Service και φανοποιείο μαζί',
        text2: 'για ακόμα μεγαλύτερη ευκολία',
      },
      {
        image: OpelOffer3,
        text1: 'Διαγνωστικά απευθείας συνδεδεμένα ',
        text2: 'με την Opel',
      },
    ],
    id: 0,
    link: 'OPEL',
    internetOffer: 'Προσφορά Internet', 
    image: OPEL, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMOPEL1017',
    serviceTitle: 'Service Γενικό για OPEL με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: 'Εξουσιοδοτημένη μονάδα OPEL',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: '4 λίτρα λάδι GM',
    li5Span: '→',
    li5: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Διαγνωστικό Global Connect συνδεδεμένο on-line με Adam Opel AG και εκπαιδευμένοι τεχνικοί',
    paragraph4Span: '* ',
    paragraph4: 'Η προσφορά ισχύει για αυτοκίνητα έως 2000 κ.εκ.',
  },
  { 
    CarouselData: 
    [
      {
        image: BmwOffer1,
        text1: 'Service και φανοποιείo',
        text2: 'για το αγαπημένο σας BMW',
      },
      {
        image: BmwOffer2,
        text1: 'Εξειδικευμένοι τεχνικοί',
        text2: 'και διαγνωστικά μηχανήματα',
      },
      {
        image: BmwOffer3,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'και πολλές ακόμα διευκολύνσεις!',
      },
    ],
    id: 1,
    link: 'BMW',
    internetOffer: 'Προσφορά Internet', 
    image: BMW, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMBMW1017',
    serviceTitle: 'Γενικό Service BMW μόνο ',
    serviceTitleSpan: '160 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου λαδιού',
    li3Span: '→',
    li3: 'Αλλαγή υγρών φρένων',
    li4Span: '→',
    li4: 'Αλλαγή 4 μπουζί',
    li5Span: '→',
    li5: '5 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 160 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr.',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Διαγνωστικά μηχανήματα ISTA/P και key reader',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
    paragraph7Span: '* ',
    paragraph7: 'Ηλεκτρονικό βιβλίο Service με σύνδεση με BMW AG',
    paragraph8Span: '* ',
    paragraph8: 'Η προσφορά ισχύει για τετρακύλινδρους κινητήρες έως 2000 κ.εκ.',
  },
  {
    CarouselData: 
    [
      {
        image: MiniOffer1,
        text1: 'Service και φανοποιείo',
        text2: 'για το αγαπημένο σας MINI',
      },
      {
        image: MiniOffer2,
        text1: 'Εξειδικευμένοι τεχνικοί',
        text2: 'και διαγνωστικά μηχανήματα',
      },
      {
        image: MiniOffer3,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'και πολλές ακόμα διευκολύνσεις!',
      },
    ],
    id: 2,
    link: 'MINI',
    internetOffer: 'Προσφορά Internet', 
    image: MINI, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMMINI1017',
    serviceTitle: 'Γενικό Service Mini μόνο ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία , ΦΠΑ και οι εξής υπηρεσίες και ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου λαδιού',
    li3Span: '→',
    li3: 'Αλλαγή υγρών φρένων',
    li4Span: '→',
    li4: 'Αλλαγή 4 μπουζί',
    li5Span: '→',
    li5: '5 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 200 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr.',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Διαγνωστικά μηχανήματα ISTA/P και key reader',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
    paragraph7Span: '* ',
    paragraph7: 'Ηλεκτρονικό βιβλίο Service με σύνδεση με BMW AG',
    paragraph8Span: '* ',
    paragraph8: 'Η προσφορά ισχύει για τετρακύλινδρους κινητήρες',
  },
  {
    CarouselData: 
    [
      {
        image: VolkswagenOffer1,
        text1: 'Εξειδικευμένο service',
        text2: 'για VW Group (VW, Audi, SEAT, Skoda)',
      },
      {
        image: AudiOffer1,
        text1: 'Eξειδικευμένα διαγνωστικά μηχανήματα',
        text2: 'για Volkswagen Group',
      },
      {
        image: SeatOffer1,
        text1: 'Επισκεφτείτε μας και για εργασίες φανοποιείου',
        text2: 'Συνεργαζόμαστε με τις μεγαλύτερες ασφαλιστικές',
      },
      {
        image: SkodaOffer1,
        text1: 'Εκπαιδευμένοι τεχνικοί',
        text2: 'για υψηλή ποιότητα εργασιών',
      },
      {
        image: VolkswagenOffer2,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'ή μεταφορά από και προς τον χώρο εργασίας σας',
      },
    ],
    id: 3,
    link: 'VOLKSWAGEN',
    internetOffer: 'Προσφορά Internet', 
    image: VOLKSWAGEN, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMVW1017',
    serviceTitle: 'Service Γενικό για Volkswagen Group (VW, Audi, SEAT, Skoda) με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: '4 λίτρα λάδι Castrol Edge 5 - 30 συνθετικό',
    li5Span: '→',
    li5: 'Αλλαγή μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Διαγνωστικό Erwin συνδεδεμένο on-line με Group VAG και εκπαιδευμένοι τεχνικοί.',
    paragraph5Span: '* ',
    paragraph5: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    CarouselData: 
    [
      {
        image: AudiOffer1,
        text1: 'Eξειδικευμένα διαγνωστικά μηχανήματα',
        text2: 'για Volkswagen Group',
      },
      {
        image: SeatOffer1,
        text1: 'Επισκεφτείτε μας και για εργασίες φανοποιείου',
        text2: 'Συνεργαζόμαστε με τις μεγαλύτερες ασφαλιστικές',
      },
      {
        image: SkodaOffer1,
        text1: 'Εκπαιδευμένοι τεχνικοί',
        text2: 'για υψηλή ποιότητα εργασιών',
      },
      {
        image: VolkswagenOffer2,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'ή μεταφορά από και προς τον χώρο εργασίας σας',
      },
      {
        image: VolkswagenOffer1,
        text1: 'Εξειδικευμένο service',
        text2: 'για VW Group (VW, Audi, SEAT, Skoda)',
      },
    ],
    id: 4,
    link: 'AUDI',
    internetOffer: 'Προσφορά Internet', 
    image: AUDI, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMVWAUDI1017',
    serviceTitle: 'Service Γενικό για Volkswagen Group (VW, Audi, SEAT, Skoda) με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή υγρών φρένων',
    li4Span: '→',
    li4: 'Αλλαγή φίλτρου λαδιού',
    li5Span: '→',
    li5: 'Αλλαγή μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Διαγνωστικό Erwin συνδεδεμένο on-line με Group VAG και εκπαιδευμένοι τεχνικοί.',
    paragraph5Span: '* ',
    paragraph5: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    CarouselData: 
    [
      {
        image: MercedesOffer1,
        text1: 'Service και φανοποιείo',
        text2: 'για το αγαπημένο σας Mercedes',
      },
      {
        image: MercedesOffer2,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'και πολλές ακόμα διευκολύνσεις!',
      },
      {
        image: MercedesOffer3,
        text1: 'Εξειδικευμένοι τεχνικοί',
        text2: 'και διαγνωστικά μηχανήματα',
      },
    ],
    id: 5,
    link: 'MERCEDES',
    internetOffer: 'Προσφορά Internet', 
    image: MERCEDES, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMMERCEDES0218',
    serviceTitle: 'Γενικό Service Mercedes μόνο ',
    serviceTitleSpan: '160 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και οι εξής υπηρεσίες και ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου καμπίνας',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου βενζίνης',
    li4Span: '→',
    li4: 'Αλλαγή φίλτρου λαδιού',
    li5Span: '→',
    li5: 'Αλλαγή μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li6Span: '→',
    li6: '5 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 160 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr.',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Εξειδικευμένα διαγνωστικά μηχανήματα',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
    paragraph7Span: '* ',
    paragraph7: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
    paragraph8Span: '* ',
    paragraph8: 'Η προσφορά ισχύει για αυτοκίνητα έως 2000 κ.εκ.',
  },
  {
    CarouselData: 
    [
      {
        image: SmartOffer1,
        text1: 'Service και φανοποιείo',
        text2: 'για το αγαπημένο σας Smart',
      },
      {
        image: SmartOffer2,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'και πολλές ακόμα διευκολύνσεις!',
      },
      {
        image: SmartOffer3,
        text1: 'Εξειδικευμένοι τεχνικοί',
        text2: 'και διαγνωστικά μηχανήματα',
      },
    ],
    id: 6,
    link: 'SMART',
    internetOffer: 'Προσφορά Internet', 
    image: SMART, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMSMART0218',
    serviceTitle: 'Γενικό Service Smart μόνο ',
    serviceTitleSpan: '120 Ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου καμπίνας',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου βενζίνης',
    li4Span: '→',
    li4: 'Αλλαγή φίλτρου λαδιού',
    li5Span: '→',
    li5: 'Αλλαγή μπουζί',
    li6Span: '→',
    li6: '3 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 120 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Εξειδικευμένα διαγνωστικά μηχανήματα',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
  },
  {
    CarouselData: 
    [
      {
        image: FordOffer1,
        text1: 'Service και φανοποιείo',
        text2: 'για το αγαπημένο σας Ford',
      },
      {
        image: FordOffer2,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'και πολλές ακόμα διευκολύνσεις!',
      },
      {
        image: FordOffer3,
        text1: 'Εξειδικευμένοι τεχνικοί',
        text2: 'και διαγνωστικά μηχανήματα',
      },
    ],
    id: 7,
    link: 'FORD',
    internetOffer: 'Προσφορά Internet', 
    image: FORD, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMFORD0218',
    serviceTitle: 'Service Γενικό Ford μονο ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li41Span: '→',
    li4: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li5Span: '→',
    li5: '4 λίτρα λάδια Castrol 5-30 συνθετικά (θεωρούνται τα καλύτερα αυτή τη στιγμή με Λιανική Tιμή 23 Ευρώ το λίτρο, ενώ εσάς συμπεριλαμβάνονται στην προσφορά των 200 Ευρώ)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Το καλύτερο λάδι για τον κινητήρα σας castroledge.gr',
    paragraph4Span: '* ',
    paragraph4: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph5Span: '* ',
    paragraph5: 'Εξειδικευμένα διαγνωστικά μηχανήματα',
    paragraph6Span: '* ',
    paragraph6: 'Εκπαιδευμένοι τεχνικοί με μεγάλη εμπειρία',
  },
  {
    CarouselData: 
    [
      {
        image: SeatOffer1,
        text1: 'Επισκεφτείτε μας και για εργασίες φανοποιείου',
        text2: 'Συνεργαζόμαστε με τις μεγαλύτερες ασφαλιστικές',
      },
      {
        image: SkodaOffer1,
        text1: 'Εκπαιδευμένοι τεχνικοί',
        text2: 'για υψηλή ποιότητα εργασιών',
      },
      {
        image: VolkswagenOffer2,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'ή μεταφορά από και προς τον χώρο εργασίας σας',
      },
      {
        image: VolkswagenOffer1,
        text1: 'Εξειδικευμένο service',
        text2: 'για VW Group (VW, Audi, SEAT, Skoda)',
      },
      {
        image: AudiOffer1,
        text1: 'Eξειδικευμένα διαγνωστικά μηχανήματα',
        text2: 'για Volkswagen Group',
      },
    ],
    id: 8,
    link: 'SEAT',
    internetOffer: 'Προσφορά Internet', 
    image: SEAT, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMSEATSKODA1017',
    serviceTitle: 'Service Γενικό για Volkswagen Group (VW, Audi, SEAT, Skoda) με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li5Span: '→',
    li5: '4 λίτρα λάδι Castrol Edge 5 - 30 συνθετικό',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Διαγνωστικό Erwin συνδεδεμένο on-line με Group VAG και εκπαιδευμένοι τεχνικοί.',
    paragraph5Span: '* ',
    paragraph5: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    CarouselData: 
    [
      {
        image: SkodaOffer1,
        text1: 'Εκπαιδευμένοι τεχνικοί',
        text2: 'για υψηλή ποιότητα εργασιών',
      },
      {
        image: VolkswagenOffer2,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'ή μεταφορά από και προς τον χώρο εργασίας σας',
      },
      {
        image: VolkswagenOffer1,
        text1: 'Εξειδικευμένο service',
        text2: 'για VW Group (VW, Audi, SEAT, Skoda)',
      },
      {
        image: AudiOffer1,
        text1: 'Eξειδικευμένα διαγνωστικά μηχανήματα',
        text2: 'για Volkswagen Group',
      },
      {
        image: SeatOffer1,
        text1: 'Επισκεφτείτε μας και για εργασίες φανοποιείου',
        text2: 'Συνεργαζόμαστε με τις μεγαλύτερες ασφαλιστικές',
      },
    ],
    id: 9,
    link: 'SKODA',
    internetOffer: 'Προσφορά Internet', 
    image: SKODA, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMSEATSKODA1017',
    serviceTitle: 'Service Γενικό για Volkswagen Group (VW, Audi, SEAT, Skoda) με ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li5Span: '→',
    li5: '4 λίτρα λάδι Castrol Edge 5 - 30 συνθετικό',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Διαγνωστικό Erwin συνδεδεμένο on-line με Group VAG και εκπαιδευμένοι τεχνικοί.',
    paragraph5Span: '* ',
    paragraph5: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    CarouselData: 
    [
      {
        image: CitroenOffer1,
        text1: 'Service και φανοποιείo',
        text2: 'για το αγαπημένο σας Citroen',
      },
      {
        image: CitroenOffer2,
        text1: 'Εξειδικευμένοι τεχνικοί',
        text2: 'και διαγνωστικά μηχανήματα',
      },
      {
        image: CitroenOffer3,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'και πολλές ακόμα διευκολύνσεις!',
      },
    ],
    id: 10,
    link: 'CITROEN',
    internetOffer: 'Προσφορά Internet', 
    image: CITROEN, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMCITROEN1017',
    serviceTitle: 'Service Γενικό για CITROEN μόνο ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li5Span: '→',
    li5: '4 λίτρα λάδι TOTAL',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    CarouselData: 
    [
      {
        image: carousel1,
        text1: 'Ελέγχουμε το αυτοκίνητό σας',
        text2: 'με τα πιο σύγχρονα διαγνωστικά μηχανήματα',
      },
      {
        image: carousel2,
        text1: 'Επισκεφτείτε το εξειδικευμένο δίκτυο συνεργείων μας',
        text2: 'για οποιαδήποτε μάρκα αυτοκινήτου διαθέτετε',
      },
      {
        image: carousel3,
        text1: 'Καλώς ήρθατε στη νέα μας ιστοσελίδα!',
        text2: 'Τώρα είναι ακόμα πιο εύκολο να βρείτε και να εκμεταλλευτείτε τις προσφορές μας',
      },
      {
        image: carousel4,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'και πολλές ακόμα διευκολύνσεις!'
      },
      {
        image: carousel5,
        text1: 'Επισκεφτείτε μας και για εργασίες φανοποιείου',
        text2: 'Συνεργαζόμαστε με τις μεγαλύτερες ασφαλιστικές',
      }
    ],
    id: 11,
    link: 'Υπόλοιπες Μάρκες',
    internetOffer: 'Προσφορά Internet', 
    image: RestBrands, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMSERVICE1017',
    serviceTitle: 'Service Γενικό μόνο ',
    serviceTitleSpan: '200 ευρώ',
    secondTitle: '',
    paragraph1: 'Στην τιμή συμπεριλαμβάνεται εργασία, ΦΠΑ και τα εξής ανταλλακτικά:',
    li1Span: '→',
    li1: 'Αλλαγή φίλτρου αέρα',
    li2Span: '→',
    li2: 'Αλλαγή φίλτρου βενζίνης',
    li3Span: '→',
    li3: 'Αλλαγή φίλτρου λαδιού',
    li4Span: '→',
    li4: 'Μπουζί χαλκού (εξαιρούνται τα μπουζί Ιριδίου ή πλατίνας)',
    li5Span: '→',
    li5: '4 λίτρα λάδι Castrol Edge 5 - 30 συνθετικό',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Χρήση γνησίων ανταλλακτικών ή ΟΕΜ (Iσχύει η εγγύηση του αυτοκινήτου - οδηγία BER 2002)',
    paragraph4Span: '* ',
    paragraph4: 'Σύγχρονα διαγνωστικά εργαλεία',
    paragraph5Span: '* ',
    paragraph5: 'Η προσφορά ισχύει για αυτοκίνητα έως 1800 κ.εκ.',
  },
  {
    CarouselData: 
    [
      {
        image: carousel1,
        text1: 'Ελέγχουμε το αυτοκίνητό σας',
        text2: 'με τα πιο σύγχρονα διαγνωστικά μηχανήματα',
      },
      {
        image: carousel2,
        text1: 'Επισκεφτείτε το εξειδικευμένο δίκτυο συνεργείων μας',
        text2: 'για οποιαδήποτε μάρκα αυτοκινήτου διαθέτετε',
      },
      {
        image: carousel3,
        text1: 'Καλώς ήρθατε στη νέα μας ιστοσελίδα!',
        text2: 'Τώρα είναι ακόμα πιο εύκολο να βρείτε και να εκμεταλλευτείτε τις προσφορές μας',
      },
      {
        image: carousel4,
        text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
        text2: 'και πολλές ακόμα διευκολύνσεις!'
      },
      {
        image: carousel5,
        text1: 'Επισκεφτείτε μας και για εργασίες φανοποιείου',
        text2: 'Συνεργαζόμαστε με τις μεγαλύτερες ασφαλιστικές',
      }
    ],
    id: 12,
    link: 'Ανακαίνιση Αυτοκινήτου',
    internetOffer: 'Προσφορά Internet', 
    image: RestBrands, 
    seeCodeOffer: 'Δείτε τον Κωδικό Προσφοράς',
    codeOffer: 'DMANAKAINISH1017',
    serviceTitle: 'Ανακαίνιση Αυτοκινήτου ',
    serviceTitleSpan: '350 ευρώ',
    secondTitle: '',
    paragraph1: 'Η προσφορά περιλαμβάνει:',
    li1Span: '→',
    li1: 'Βαφή τεσσάρων κομματιών (πχ. καπώ, προφυλακτήρας και 2 φτερά)',
    paragraph2: 'Επιπλέον περιλαμβάνονται: έλεγχος 22 σημείων, λιπάνσεις, συσφίξεις.',
    paragraph3Span: '* ',
    paragraph3: 'Η προσφορά δεν περιλαμβάνει ανταλλακτικά εφόσον χρειαστούν ή εργασίες σφυρηλάτησης (εργασίες φανοποιίας)',
    paragraph4Span: '* ',
    paragraph4: 'Εξαιρείται από τα κομμάτια ο ουρανός του αυτοκινήτου',
    paragraph5Span: '* ',
    paragraph5: 'Χρησιμοποιούμε χρώματα υδατοδιαλυτά SIKKENS με 5ετή εγγύηση.',
  },
]