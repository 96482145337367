import FormSignup from './FormSignup';

const Form = () => {
  return (
    <>
      <div className='form-container'>
        <div className='form-content'>          
          <FormSignup/>         
        </div>
      </div>
    </>
  );
};

export default Form;