import { useState } from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = ({ id, solution, title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className='accordion'>
      { isActive ?
        <div key={id} className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div>{ isActive ? <ExpandMoreIcon /> :  <ChevronRightIcon /> }</div>
          <div>{ solution }</div>
        </div> :
        <div key={id} className="isnotactive-accordion-title" onClick={() => setIsActive(!isActive)}>
          <div>{ isActive ? <ExpandMoreIcon /> :  <ChevronRightIcon /> }</div>
          <div>{ solution }</div>
        </div> 
      }
      { isActive &&
        <div className="accordion-content">
          <h4>{ title }</h4>
          <p>{ content }</p>
        </div>
      }
    </div>
  );
};

export default Accordion;