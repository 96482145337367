import BrandOffer from './BrandOffer'
import { useEffect } from 'react'
import './homepageBrands.css'

const HomepageBrands = () => {
  
  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <div className="offers">
      <h2>Προσφορές</h2>
      <BrandOffer />
    </div>
  );
}
 
export default HomepageBrands;