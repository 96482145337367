export const accordionData = [
  { id: 1,
    solution: 'Πληρώνετε πολύ ακριβά την απαραίτητη επισκευή του αυτοκινήτου σας;', 
    title: 'Προσφέρουμε ανταλλακτικά ΟΕΜ.', 
    content: `Εφόσον το επιλέξετε μπορείτε να χρησιμοποιήσετε ανταλλακτικά Ο.Ε.Μ. Ευρωπαίων κατασκευαστών σε σημαντικά χαμηλότερες τιμές. Σύμφωνα με τον 
              κανονισμό BER 2002 η χρήση τέτοιων ανταλλακτικών είναι απόλυτα συμβατή με την εγγύηση του αυτοκινήτου. Με άλλα λόγια εξοικονομείτε έως και 50% από 
              τις λιανικές τιμές της αντιπροσωπείας, ενώ η εγγύηση του αυτοκινήτου σας συνεχίζει να ισχύει κανονικά. Επίσης τα ανταλλακτικά αυτά και η αντίστοιχη 
              εργασία συνοδεύονται από 1 έτος εγγύηση.`
  },
  { 
    id: 2,
    solution: 'Θέλετε να συντηρήσετε το αυτοκίνητό σας ώστε να μην αναγκαστείτε να το αντικαταστήσετε;', 
    title: 'Παρέχουμε πλήρη ανακαίνιση αυτοκινήτου.', 
    content: `Μια νέα επαναστατική προσφορά για τους φίλους μας που έχουν αυτοκίνητο 7 ετών και πάνω. Μην αφήνετε το παλιό σας αυτοκίνητο στην τύχη του. 
              Κάντε μια ολική ανακαίνιση εκμεταλλευόμενοι το νέο μας πακέτο και κάντε το αυτοκίνητό σας καινούργιο.`
  },
  {
    id: 3,
    solution: 'Χρειάζεστε μέσο μεταφοράς όσο επισκευάζουμε το αυτοκίνητό σας;', 
    title: 'Παρέχουμε αυτοκίνητο αντικατάστασης.', 
    content: `Εφόσον είστε πελάτης του φανοποιείου ή κάνετε επισκευή άνω της μίας ημέρας.`
  },
  {
    id: 4,
    solution: 'Δυσκολεύεστε να εμπιστευτείτε τις χρεώσεις που γίνονται, ειδικά σε εργασίες εκτός προγραμματισμένου service;', 
    title: 'Στην εταιρεία μας η διαφάνεια στις χρεώσεις αποτελεί πρώτη προτεραιότητα.', 
    content: `Δεν χρειάζεται να είστε τεχνικός για να καταλάβετε τις χρεώσεις μας. Συγκεκριμένα: Οι χρεώσεις για όλες τις εργασίες γίνονται με βάση την 
              εργατοώρα και τον πρότυπο χρόνο που δίνει ο κατασκευαστής για τη συγκεκριμένη εργασία. Για τα ανταλλακτικά έχετε τη δυνατότητα επιλογής μεταξύ γνήσιων 
              ή φθηνότερων αλλά εξίσου ποιοτικών ΟΕΜ (Original Equipment Manufacturer) ανταλλακτικών. Ο τεχνικός μας σύμβουλος σας εξηγεί εκ των προτέρων τις τιμές 
              μας και σας δίνει προσφορά. Στο τέλος του service παραλαμβάνετε όλα τα χρησιμοποιημένα ανταλλακτικά. Ο τεχνικός μας σύμβουλος σας εξηγεί το τιμολόγιο 
              και το συμφωνεί με την αρχική προσφορά που σας έδωσε.`
  },
  {
    id: 5,
    solution: 'Ανησυχείτε για την ποιότητα των επισκευών;', 
    title: 'Προσφέρουμε 1 έτος εγγύηση μηχανικών επισκευών και 5 έτη εγγύηση για τις εργασίες του φανοποιείου!', 
    content: `Για οποιαδήποτε εργασία στο αυτοκίνητό σας μας εμπιστευθείτε, σας προσφέρουμε εγγύηση επισκευής για τη συγκεκριμένη εργασία χωρίς περιορισμούς 
              (χιλιομέτρων ή άλλους).`
  },
  {
    id: 6,
    solution: 'Δεν έχετε χρόνο να πάτε για επισκευή το αυτοκίνητό σας;', 
    title: 'Παρέχουμε υπηρεσία Pick up and Delivery.', 
    content: `Εφόσον μας το ζητήσετε μπορούμε να παραλάβουμε για επισκευή και να σας επιστρέψουμε το αυτοκίνητό σας σε εργάσιμες ώρες.`
  },
];