import carousel1 from '../../images/carousel_1.jpg'
import carousel2 from '../../images/carousel_2.jpg'
import carousel3 from '../../images/carousel_3.jpg'
import carousel4 from '../../images/carousel_4.jpg'
import carousel5 from '../../images/carousel_5.jpg'

export const CarouselData = [
  {
    image: carousel1,
    text1: 'Ελέγχουμε το αυτοκίνητό σας',
    text2: 'με τα πιο σύγχρονα διαγνωστικά μηχανήματα',
  },
  {
    image: carousel2,
    text1: 'Επισκεφτείτε το εξειδικευμένο δίκτυο συνεργείων μας',
    text2: 'για οποιαδήποτε μάρκα αυτοκινήτου διαθέτετε',
  },
  {
    image: carousel3,
    text1: 'Καλώς ήρθατε στη νέα μας ιστοσελίδα!',
    text2: 'Τώρα είναι ακόμα πιο εύκολο να βρείτε και να εκμεταλλευτείτε τις προσφορές μας',
  },
  {
    image: carousel4,
    text1: 'Δωρεάν αυτοκίνητο αντικατάστασης',
    text2: 'και πολλές ακόμα διευκολύνσεις!'
  },
  {
    image: carousel5,
    text1: 'Επισκεφτείτε μας και για εργασίες φανοποιείου',
    text2: 'Συνεργαζόμαστε με τις μεγαλύτερες ασφαλιστικές',
  }
]