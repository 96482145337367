import React, {useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import validate from './validateInfo';
import useForm from './useForm';
import './form.css';
import SmallAlert from "../../../components/screenComponents/modals/smallAlert"

const FormSignup = ({ submitForm }) => {

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const { handleChange, handleSubmit, values, errors, isSubmitting } = useForm(
    setShowSuccess,
    setShowError,
    validate
  );

  return (
    <>
      <form className='form' noValidate>
        <div className='form-inputs'>
          <label className='form-label'>Το όνοματεπώνυμό σας<span className="asterisk"> *</span></label>
          <div className='handle-inputs'>
            <input
              className='form-input'
              type='text'
              name='fullName'
              value={values.fullName}
              onChange={handleChange}
            />
            {errors.fullName && <span className='error-message'>{errors.fullName}</span>}
          </div>
        </div>

        <div className='form-inputs'>
          <label className='form-label'>Τηλέφωνο επικοινωνίας<span className="asterisk"> *</span></label>
          <div className='handle-inputs'>
            <input
              className='form-input'
              type='text'
              name='phoneNumber'
              value={values.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && <span className='error-message'>{errors.phoneNumber}</span>}
          </div>
        </div>

        <div className='form-inputs'>
          <label className='form-label'>Η διεύθυνση email σας</label>
          <div className='handle-inputs'>
            <input
              className='form-input'
              type='email'
              name='email'
              value={values.email}
              onChange={handleChange}
              />
          </div>
        </div>

        <div className='form-inputs'>
          <label className='form-label'>Μήνυμα<span className="asterisk"> *</span></label>
          <div className='handle-inputs'>
            <textarea 
              className='form-input'
              name="message" 
              // rows="6" 
              // cols="40"
              value={values.message}
              onChange={handleChange}> 
            </textarea>
            {errors.message && <span className='error-message'>{errors.message}</span>}
          </div>
        </div>
      </form>
      <button 
        className='form-btn'  
        onClick = {handleSubmit} 
        type='submit'>
        Αποστολή μηνύματος
        {isSubmitting && 
          <CircularProgress 
            color="inherit" 
            style={{ marginLeft: 6 }} 
            size={'1.5rem'}            
          />                  
        }
      </button>
      <SmallAlert
        open={showSuccess}
        styling="info"
        heading={"Λάβαμε το μήνυμά σας!"}
        onClose={() => setShowSuccess(false)}
        message1={"Σύντομα ένας εκπρόσωπός μας θα επικοινωνήσει μαζί σας."}
        message2={"Ευχαριστούμε πολύ για την επικοινωνία."}    
      />
      <SmallAlert
        open={showError}
        styling="danger"
        heading={"Λανθασμένη αποστολή email"}
        onClose={() => setShowError(false)}
        message={"Προσπαθείστε ξανά αργότερα"} />       
    </>
  );
};

export default FormSignup;