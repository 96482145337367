import { Link } from 'react-router-dom';
import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const OfferSidebar = () => {
  const [offerExpand, setOfferExpand] = useState(true)

  return (
    <div className="car-offers-sidebar">
      <div className="company-details">
        <p>Η εταιρεία μας με πάνω από 30 χρόνια παρουσίας στο χώρο του αυτοκινήτου δημιούργησε για εσάς ειδικό τμήμα για την 
          αγαπημένη σας μάρκα που διαθέτει εξειδικευμένο συνεργείο και φανοποιείο με εκπαιδευμένους τεχνικούς.
        </p>
        <p className='bold-text'>Επισκεφθείτε το εξειδικευμένο κατάστημά μας</p>
        <p className="main-color">Οδός Μεσογείων 131 Μαρούσι</p>
        <p className='bold-text'>Καλέστε μας για πληροφορίες ή ραντεβού στο</p>
        <a href="tel: 2106107623" className="main-color">2106107623</a>
        <p>Κάντε κλικ <span><Link className="main-color" to='/contact-us'>εδώ</Link></span> για εμφάνιση του χάρτη ή <span><Link className="main-color" to='/contact-us'>εδώ</Link></span> για οδηγίες προς το συνεργείο </p>
      </div>
      <div className='offers-flex-sidebar'>
        <div className="offers-sidebar">
          <p>ΔΩΡΕΑΝ αυτοκίνητο αντικατάστασης</p>
          <p>ΔΩΡΕΑΝ πλύσιμο αυτοκινήτου</p>
          <p>ΔΩΡΕΑΝ παραλαβή κι επιστροφή του αυτοκινήτου σας</p>
          <Link to='/services' className="more-offers-sidebar-link">
            Δείτε περισσότερα
            <ArrowForwardIosIcon 
              className="more-offers-sidebar-link-icon"
            />
          </Link>
        </div>
        <div className="offers-sidebar">
          <p>Νέα δυνατότητα εξόφλησης με άτοκες δόσεις μέσω πιστωτικής κάρτας</p>
        </div>
        <div className="offers-sidebar">
          <p>Εγγύηση 1 έτος για μηχανικές εργασίες</p>
          <p>&</p>
          <p>5 έτη για εργασίες βαφής</p>
        </div>
      </div>
      <div className="sidebar-more-services">
        {offerExpand ?
        <div className="accordion-sidebar" onClick={() => setOfferExpand(!offerExpand)}>
          <p>Τιμοκατάλογος για άλλες εργασίες</p>
          <ExpandMoreIcon />
        </div>
          : 
        <div onClick={() => setOfferExpand(!offerExpand)}>
          <p className='accordion-border-bottom'>Τιμοκατάλογος για άλλες εργασίες</p>
        </div>
        }
        {!offerExpand &&
          <div className='accordion-sidebar-content'>
            <ul className="accordion-sidebar-content-list">
              <li>Αλλαγή λαδιών 5W - 30 συνθετικό (4 λίτρα λάδι, φίλτρο και εργασία) <span>120 Ευρώ</span></li>
              <li>Αλλαγή λαδιών 10W-40 ημισυνθετικό (4 λίτρα λάδι, φίλτρο και εργασία) <span>100 Ευρώ</span></li>
              <li>Ευθυγράμμιση τροχών <span>25 Ευρώ</span></li>
              <li>Ευθυγράμμιση τροχών με κάθε αλλαγή ελαστικών <span>ΔΩΡΕΑΝ</span></li>
              <li>Προέλεγχος ΚΤΕΟ <span>19 Ευρώ</span></li>
              <li>Καθαρισμός Μπεκ <span>69 Ευρώ</span></li>
              <li>Έλεγχος Air-Condition <span>ΔΩΡΕΑΝ</span></li>
              <li>Κάρτα καυσαερίων <span>10 Ευρώ</span></li>
            </ul>
            <ExpandLessIcon 
              onClick={() => setOfferExpand(!offerExpand)}
            />
          </div>
        }
      </div>
    </div>
  );
}
 
export default OfferSidebar;