import { Link } from "react-router-dom";
import './contactSectionHomepage.css'

const ContactSection = () => {
  return (
    <div className="homepage-contact-section">
      <div className='homepage-contact-section-flex'>
        <button className='homepage-contact-section-button'>
          <Link to="/contact-us">Κλείστε online το ραντεβού σας</Link>
        </button>
        <p>ή</p>
        <button className='homepage-contact-section-button'>
          <a href="tel:210 6107623">
            Καλέστε μας
          </a>
        </button>
      </div>
    </div>
  );
}
 
export default ContactSection;