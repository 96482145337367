import Carousel from "../../components/screenComponents/carousel/Carousel";
import imageMarousi from '../../components/images/katastima-marousi.jpeg'
import imageLikovrisi from '../../components/images/katastima-likovrisi.jpeg'
import { useEffect } from "react";
import './workShops.css'
import { CarouselData } from "../../components/screenComponents/carousel/CarouselData";

const WorkShops = () => {

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <>
      <Carousel
        slides={CarouselData}
      />
      <div className="workshops-section">
        <h2>Συνεργεία</h2>

        <div className="workshop">
          <div className='workshop-image'>
            <img src={imageMarousi} alt="" />
            <p>Συνεργείο Φανοβαφείο</p>
            <p>Μέγεθος 4.500 τ.μ.</p>
          </div>
          <div className="workshop-info">
            <h3>Κεντρικά Γραφεία</h3>
            <div className="workshop-info-text">
              <p><strong>Διεύθυνση:</strong> οδός Μεσογείων 131 Μαρούσι</p>
              <a href='https://www.google.com/maps?q=%CE%9C%CE%B5%CF%83%CE%BF%CE%B3%CE%B5%CE%AF%CF%89%CE%BD+131,+%CE%9C%CE%B1%CF%81%CE%BF%CF%8D%CF%83%CE%B9+151+26' rel='noreferrer' target='_blank'>
                Bρείτε μας στο χάρτη
              </a>
            </div>
            <div className="workshop-info-text">
              <p><strong>Τηλέφωνο:</strong> 2106107623</p>
              <p><strong>Fax:</strong> 2106109405</p>
              <a href='tel: 2106107623' rel='noreferrer'>
                Καλέστε μας
              </a>
            </div>
            <div className="workshop-info-text">
              <p><strong>E-mail:</strong> servicedynamotors@gmail.com</p>
              <a href="mailto: servicedynamotors@gmail.com" rel='noreferrer'>
                Στείλτε μας email
              </a>
            </div>
          </div>
        </div>

        <div className="workshop">
          <div className='workshop-image'>
            <img src={imageLikovrisi} alt="" />
            <p>Συνεργείο</p>
            <p>Μέγεθος 300 τ.μ.</p>
          </div>
          <div className="workshop-info">
            <div className="workshop-info-text">
              <p><strong>Διεύθυνση:</strong> Λεωφ. Σοφοκλή Βενιζέλου, Λυκόβρυση</p>
              <a href='https://www.google.com/maps?q=%CE%9B%CE%B5%CF%89%CF%86.+%CE%A3%CE%BF%CF%86%CE%BF%CE%BA%CE%BB%CE%AE+%CE%92%CE%B5%CE%BD%CE%B9%CE%B6%CE%AD%CE%BB%CE%BF%CF%85+112,+%CE%9B%CF%85%CE%BA%CF%8C%CE%B2%CF%81%CF%85%CF%83%CE%B7+141+23' rel='noreferrer' target='_blank'>
                Bρείτε μας στο χάρτη
              </a>
            </div>
            <div className="workshop-info-text">
              <p><strong>Τηλέφωνο:</strong> 2102853682</p>
              <a href='tel: 2106107623' rel='noreferrer'>
                Καλέστε μας
              </a>
            </div>
            <div className="workshop-info-text">
              <p><strong>E-mail:</strong> servicedynamotors@gmail.com</p>
              <a href="mailto: servicedynamotors@gmail.com" rel='noreferrer'>
                Στείλτε μας email
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default WorkShops;