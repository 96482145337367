import Form from './form/Form';
import ContactInfo from './ContactInfo';
import './contact.css';
import { useEffect } from 'react';
import GoogleMaps from './GoogleMaps';

const Contact = () => {

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <>
      <GoogleMaps />
      <div className='contact-section'>
        <h2>Επικοινωνία</h2>
          <div className="contact">
            <Form />
            <ContactInfo />
          </div>
      </div>
    </>
  );
}
 
export default Contact;