import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import React, { Component } from 'react'

export class GoogleMaps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: [
        {lat: 38.0407648, lng: 23.819549},
        {lat: 38.0737716, lng: 23.7920394},
      ]
    }
  }

  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      return (
        <Marker 
          key={index} 
          id={index} 
          position={{
            lat: store.latitude,
            lng: store.longitude
          }}
        />
      )
    })
  }

  render() {
    return (
        <Map
          google={this.props.google}
          zoom={13.6}
          initialCenter={{ lat: 38.0580206, lng: 23.8025753 }}
          style={mapStyles}
          className='googlemaps'
        >
          <Marker position={{ lat: 38.0407648, lng: 23.819549 }} />
          <Marker position={{ lat: 38.0737716, lng: 23.7920394 }} />
        </Map>
    );
  }
}

const mapStyles = {
  width: '100%',
  marginTop: '50px',
  height: '600px',
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyA0ebK06vA_k6W2Kvq9qRCFa01s1GQqgRw&callback'
})(GoogleMaps);